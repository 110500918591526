exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acercade-js": () => import("./../../../src/pages/acercade.js" /* webpackChunkName: "component---src-pages-acercade-js" */),
  "component---src-pages-bebidaspreparadas-js": () => import("./../../../src/pages/bebidaspreparadas.js" /* webpackChunkName: "component---src-pages-bebidaspreparadas-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cocteles-js": () => import("./../../../src/pages/cocteles.js" /* webpackChunkName: "component---src-pages-cocteles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-90-sgiveaway-js": () => import("./../../../src/pages/lp/90sgiveaway.js" /* webpackChunkName: "component---src-pages-lp-90-sgiveaway-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-sabores-cool-raspberry-js": () => import("./../../../src/pages/sabores/cool-raspberry.js" /* webpackChunkName: "component---src-pages-sabores-cool-raspberry-js" */),
  "component---src-pages-sabores-delicious-apple-js": () => import("./../../../src/pages/sabores/delicious-apple.js" /* webpackChunkName: "component---src-pages-sabores-delicious-apple-js" */),
  "component---src-pages-sabores-exotic-berry-js": () => import("./../../../src/pages/sabores/exotic-berry.js" /* webpackChunkName: "component---src-pages-sabores-exotic-berry-js" */),
  "component---src-pages-sabores-index-js": () => import("./../../../src/pages/sabores/index.js" /* webpackChunkName: "component---src-pages-sabores-index-js" */),
  "component---src-pages-sabores-pink-lemonade-js": () => import("./../../../src/pages/sabores/pink-lemonade.js" /* webpackChunkName: "component---src-pages-sabores-pink-lemonade-js" */),
  "component---src-pages-sabores-sangria-js": () => import("./../../../src/pages/sabores/sangria.js" /* webpackChunkName: "component---src-pages-sabores-sangria-js" */),
  "component---src-pages-sabores-strawberry-hill-js": () => import("./../../../src/pages/sabores/strawberry-hill.js" /* webpackChunkName: "component---src-pages-sabores-strawberry-hill-js" */),
  "component---src-pages-sabores-sunpeakpeach-js": () => import("./../../../src/pages/sabores/sunpeakpeach.js" /* webpackChunkName: "component---src-pages-sabores-sunpeakpeach-js" */),
  "component---src-pages-sabores-tropical-js": () => import("./../../../src/pages/sabores/tropical.js" /* webpackChunkName: "component---src-pages-sabores-tropical-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-pages-uso-de-marca-js": () => import("./../../../src/pages/uso-de-marca.js" /* webpackChunkName: "component---src-pages-uso-de-marca-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-cat-post-js": () => import("./../../../src/templates/cat-post.js" /* webpackChunkName: "component---src-templates-cat-post-js" */),
  "component---src-templates-tag-post-js": () => import("./../../../src/templates/tag-post.js" /* webpackChunkName: "component---src-templates-tag-post-js" */)
}

